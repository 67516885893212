import React from "react";

const Constitution=()=>{
    return (<>
        <div className="constitution">
            <h1>Constitution</h1>
            <h2>The Constitution of the Great Barton Allotments Association</h2> 
            <h3>“Growing together”</h3>
            <ol>
                <li><b>The name of the association shall be:</b> Great Barton Allotments Association.</li>
                <li><b>The objectives of the Association shall be:</b>
                    <ul>
                        <li>To promote the interests of the members with regard to the proper cultivation, good management and enjoyment of allotments and leisure gardens.</li>
                        <li>To maintain and improve facilities which will protect members and the site against damage, trespass and theft.</li>
                        <li>To conduct negotiations with the local council and wherever possible co-operate with them, other groups and organizations and individuals in furtherance of these objectives.</li>
                    </ul>
                </li>
                <li><b>Membership of the association will be:</b>
                    <ul>
                        <li>Subject to the discretion of the Committee.</li>
                        <li>Allotment tenants who have paid the annual subscription.</li>
                        <li>Garden members who have paid the annual subscription.</li>
                        <li>The use of the term ‘member’ within this constitution shall mean a fully paid up member.</li>
                    </ul>
                </li>
                <li><b>Management Committee:</b>
                    <ul>
                        <li>The affairs of the Association shall be conducted by a committee of members elected at the annual general meeting.</li>
                        <li>The committee shall consist of a Chairman, Vice-Chairman, Secretary and Treasurer plus a minimum of two and a maximum of four members.</li>
                        <li>No member shall hold more than one post on the committee.</li>
                        <li>All officers and members of the committee shall retire each year.</li>
                        <li>Officers and members shall be eligible for re-election each year.</li>
                        <li>The Committee shall have the power to co-opt members to fill vacancies which arise between AGMs.</li>
                        <li>The Committee shall have the power to co-opt additional non-voting members for specific tasks up to a maximum of four.</li>
                        <li>Five members of the Committee shall form a quorum.</li>
                        <li>The Chairman (in their absence, the Vice-Chairman or other committee member appointed for the purpose of the meeting) shall chair all meetings of the association and it’s Committee.</li>
                        <li>The Committee shall meet to discuss issues relating to the Association no less than once each quarter.</li>
                        <li>The Secretary shall record the minutes of each meeting which will be available to all members for inspection at reasonable notice.</li>
                        <li>The Committee shall have the power to expel any member who offends against the Constitution of the Association or whose conduct in the opinion of the Committee renders the member unfit.</li>
                        <li>The Secretary shall send any member qualifying for possible expulsion, written notice of the complaint and request attendance within seven days at a meeting with the Committee.</li>
                        <li>No member may be expelled from the Association without having an opportunity to appear before the Committee to answer the complaint.</li>
                        <li>Members may only be expelled if it is the decision of a majority vote by the Committee, at least three members voting in favour of the expulsion.</li>
                    </ul>
                </li>
                <li><b>Association meetings:</b>
                    <ul>
                        <li>An Annual General Meeting (AGM) shall be held in October of each year.</li>
                        <li>At least seven days advance notice of the date and agenda of any General meeting shall be circulated to the members.</li>
                        <li>Items for the agenda of any General Meeting shall be communicated to the Secretary a sufficient time in advance to allow preparation for the meeting. A sufficient time being not less than 21 days.</li>
                        <li>At the AGM the accounts and Secretary’s reports shall be submitted.</li>
                        <li>All paid up members of the association shall have one vote each.</li>
                        <li>The quorum at any General Meeting shall be 20 members or 25% of the membership, whichever is the lesser.</li>
                        <li>In the event of a quorum not being present at a general meeting then the meeting shall be adjourned to a date and specified by the Committee, not less than fourteen days later, in order to circulate the new date and agenda according to the constitution.In the event of a quorum not being present at the adjourned meeting, the members present shall be deemed a quorum for the meeting. Any member shall be entitled to invite guests to the association meetings, provided that no person whose application for membership has been declined or who has been expelled from the Association is invited.</li>
                        <li>An Extraordinary General Meeting (EGM) may be called at the request of 10% of the membership.</li>
                    </ul>
                </li>
                <li><b>Business to be conducted at an AGM:</b>
                    <ul>
                        <li>The minutes of the previous years AGM.</li>
                        <li>Matters arising from the minutes of the previous years AGM.</li>
                        <li>Adoption of the previous years AGM.</li>
                        <li>The Chairman’s Report.</li>
                        <li>The Secretary’s Report.</li>
                        <li>The presentation of the accounts for the previous year, ending 30th September, by the Treasurer.</li>
                        <li>Adoption of the accounts for the previous financial year.</li>
                        <li>The election of Officers and committee members.</li>
                        <li>The appointment of auditors.</li>
                        <li>Such other business as has been communicated to the secretary and appears on the agenda included in the notice of the meeting.</li>
                    </ul>
                </li>
                <li><b>Finance:</b>
                    <ul>
                        <li>The Association’s year will run from the First of October to the Thirtieth of September.</li>
                        <li>Every member of the Association shall pay an annual subscription to be fixed at each AGM, payable at the AGM or within fourteen days of the AGM.</li>
                        <li>If there is a failure to pay the annual subscription by the last day of November in that year, the Secretary shall send notice to the last known address for payment within fourteen days. If unpaid after this, the membership may be terminated without further notice.</li>
                        <li>No monies or property of the Association, or any gain arising from carrying on the business shall be used other than for the benefit of the Association and its members or any purpose chosen by resolution at an AGM or EGM.</li>
                        <li>All members of the Committee shall keep accurate records of their dealings on behalf of the Association and shall be entitled to claim re-imbursement of reasonable expenses incurred on behalf of the Association. Reasonable expenses will be agreed by the Committee.</li>
                        <li>If, at any AGM or EGM, a resolution for the dissolution of the Association is passed by a majority of the members present, the Committee shall immediately, or on a date specified by the resolution, realize the assets of the Association. Any assets remaining, after the satisfaction of all debts and liabilities, shall be transferred to a similar organisation or charity of the Committee’s choice.</li>
                    </ul>
                </li>
                <li><b>Bank Account:</b>
                    <ul>
                        <li>The Committee shall maintain a bank account in the name of the Association and all monies received from any source on behalf of the Association shall be paid into that account.</li>
                        <li>Records and accounts of all transactions shall be kept by the treasurer and shall be available for inspection by the members.</li>
                        <li>The accounts for the preceding year shall be presented at the AGM.</li>
                        <li>Cheques shall be signed by any two of three signatories.</li>
                    </ul>
                </li>
            </ol>
            <p><b>Agreed by members and signed on behalf of the members.</b></p>
            <p><b>This tenth day of October, in the year 2016</b><br/></p>
            <p><b>Chairman:</b> Peter Fisk<br/></p>
            <p><b>Secretary:</b> Lesley Linden</p>
        </div>
    </>)
}

export default Constitution