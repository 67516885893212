import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import img1 from  "../../public/assets/images/insta1.png"

const topMonthlyTasks = [
    {
        month: "january",
        desc: "Only the mildest of days are likely to drag you out to the allotment in Jan. Spend time ordering seeds and sketch out a plan for what you want to grow and the layout of your plot"
    },
    {
        month: "february",
        desc: "The weather in Feb can swing from one extreme to the other. From grey days with persistent rain or sleet to a sudden spell of bright, sunny days. Provided the ground is not frozen or too wet to work, complete your winter digging or mulch with cardboard but always incorporate as much organic matter as you can."
    },
    {
        month: "march",
        desc: "As winter begins to recede, sowing and planting outdoors can start. Probably the most useful thing you can do this month is begin preparing beds for sowing and planting. Remove all weeds that have survived the winter, rake the solid thoroughly and apply some fertilizer such as chicken manure or blood fish and bone."
    },
    {
        month: "april",
        desc: "April is a lean month for harvesting but it is a good month to get ahead by sowing seeds indoors and outside in cold frames. Start to put traps and controls in place for slugs and snails. An early start prevents the slugs from multiplying and babies from maturing. Research types of netting and get them prepared for use in May and June."
    },
    {
        month: "may",
        desc: "May can be a seductive month with longer days, higher temperatures and hopefully enough warm sunshine. Begin planting and sowing in ernest,however only transplant tender seedlings when the last chance of frost has gone. Harden off any plants grown indoors before planting out and be aware of the possibility of night time attacks from slugs. GBA spring tidy in May or June"
    },
    {
        month: "june",
        desc: "June signals the mid-point of the year, the beginning of summer. It offers the last opportunity to sow many seeds, however, it does offer the first opportunity to harvest some crops. You would be unlucky to experience frosts now but unfortunately attacks from insects, birds, slugs and snails are more likely. Remember to NET. Manage your water butts for legionella"
    },
    {
        month: "july",
        desc: "It’s the height of the summer. The days are long, temperatures are likely to be at their highest and all being well you are harvesting something. July can often be a dry month so watering is crucial. Manage your water butts for legionella"
    },
    {
        month: "august",
        desc: "Almost everything you have sown, planted and nurtured through the spring and early summer will be coming to fruition now. Daily trips to your plot should see you returning home with everything from peas, beans, carrots, beetroot, sweetcorn, tomatoes, peppers, potatoes, onions and salads to berries currants and other fruits. In a good year!"
    },
    {
        month: "september",
        desc: "This marks the onset of autumn. At this time of the year early crops will be coming to an end. Later crops are coming into harvest now. Lift maincrop potatoes, leeks, picking apples, pears and late season plums. The season is not over yet! GBA Autumn Tidy"
    },
    {
        month: "october",
        desc: "It is starting to feel as if the year is on the turn. The days are noticeably shorter, the sun lower in the sky and temps dropping. Leaves are changing colour and it is a good time collect them for your beds before you mulch with manure. Early frosts are likely and it is time to have a good tidy and clear up, composting the remains for the summers harvest."
    },
    {
        month: "november",
        desc: "These are the months to ensure you have a sharp pair of secateurs to hand as pruning begins in ernest. Fruit bushes and trees will have shed their leaves and will be entering their dormant phase, so now is the time for pruning, with the exception of Cherries and Plums. Start the winter digging or if using the no dig method cover beds with cardboard.. Apply compost and well-rotted manure to the beds, even those with cardboard. Mulch around the base of existing fruit trees and bushes."
    },
    {
        month: "december",
        desc: "These are the months to ensure you have a sharp pair of secateurs to hand as pruning begins in ernest. Fruit bushes and trees will have shed their leaves and will be entering their dormant phase, so now is the time for pruning, with the exception of Cherries and Plums. Start the winter digging or if using the no dig method cover beds with cardboard.. Apply compost and well-rotted manure to the beds, even those with cardboard. Mulch around the base of existing fruit trees and bushes."
    }
]

const JobsOnThePlot = () => {
    const navigate = useNavigate()
    const page = useLocation().pathname.slice(15)
    const d = new Date()
    const currentMonthName = topMonthlyTasks[d.getMonth()].month
    const monthlyTasks = topMonthlyTasks.find((task, index) => { 
        if (task.month == page) { 
            return true 
        }else if(index==d.getMonth()){
            return true
        }else{

        }
    })

    const buttonClass=(month)=>{
        if(page==month.month){
            return "activeButton"
        }else if(page=="" && month.month==currentMonthName){
            return "activeButton"
        }else{
            return ""
        }
    }
    return (<>
        <div className="jobsOnThePlotPage">
            <h3>{monthlyTasks.month.slice(0,1).toUpperCase()+monthlyTasks.month.slice(1)+" - Jobs of the plot"}</h3>
            <nav>
                {topMonthlyTasks.map((month, index)=>{
                    return <button key={index}
                        className={buttonClass(month)}
                        onClick={(e)=>{
                           navigate(`/jobsontheplot/${month.month}`)
                        }}>
                        {month.month.slice(0,1).toUpperCase()+month.month.slice(1)}
                    </button>
                })}
            </nav>
            <p>{monthlyTasks.desc}</p>
            <img src={img1} />
            <span>
                <p>From time to time general maintenance of the communial area needs attention, members can considerably help performing the following:
                    There are more jobs on the allotment than just what is happening on each plot therefore, we work together to maintain
                    the communal areas regularly in the year. 
                </p>
                <p>
                    There are also two general clean-up days every plot holder should attend,
                    in order to do more complicated jobs.  These are scheduled in advance and everyone is invited.  The purpose of these is to spread the load of maintenance jobs in what is a community based garden.  These jobs include; cutting hedges, repairing fences, sorting out the contents of the container and polytunnel, amongst other things.  All Plot Holders are encouraged to attend these days. It is usually just a few hours and we try to make it as fun as possible.  It's also a great way to meet people on site that you may not have bumped into before.
                </p>
                <p>
                    Further to this  there is an annual general meeting in the fall.   This is the time that contracts are renewed and the committee provides any updates that are relevant.  More importantly, it is a time to elect new and or existing committee members.   The committee serves as an important role in maintaining the integrity of the original goals when the allotment was introduced into Great Barton in 2012.
                    It is not an exclusive club and anyone can put or have their name put forward to serve on the committee.  <Link to="about/rules">For more infomation click here.</Link>
                </p>
                <ul>
                    <li>
                        It is very important to keep your plot and surrounding paths free of weeds so as not to let them encroach on the hard work of plot holders around you.  Spreading weeds either by root or wind is a constant battle but it is made much easier by regularly attending to them. 
                    </li>
                    <li>
                        Managing any potential hazards is also important, these include things such as sprawling netting,  structures that hold netting or other structures such as bean poles.
                    </li>
                    <li>
                        Further to this keeping tools, water cans and the like out of sight to not encourage thieving visitors.
                    </li>
                </ul>
            </span>
        </div>
    </>)
}

export default JobsOnThePlot