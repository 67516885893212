import React from "react";
import { Link } from "react-router-dom";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai"
import { IoLogoYoutube } from "react-icons/io"

const LinksPage = () => {
    return (
        <div className="linksPage">
            <nav>
                <h3>Socials</h3>
                <ul>
                    <li>
                        <Link
                            title="Facebook"
                            to={"https://www.facebook.com/profile.php?id=61550843213230"}
                            target="_blank" rel="noopener noreferrer">
                            <AiFillFacebook />
                        </Link>
                        <Link
                            title="Youtube"
                            to={"https://www.youtube.com/@gballotments3821"}
                            target="_blank" rel="noopener noreferrer">
                            <IoLogoYoutube />
                        </Link>
                        <Link
                            title="Instagram"
                            to={"https://www.instagram.com/gballotments/"}
                            target="_blank" rel="noopener noreferrer">
                            <AiFillInstagram />
                        </Link>
                    </li>
                </ul>
                <h3 >Great Barton Websites</h3>
                <ul>
                    <li>
                        <Link
                            title="Great Barton Parish Council"
                            to="http://greatbarton.suffolk.cloud/"
                            target="_blank" rel="noopener noreferrer">
                            Great Barton Parish Council
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Great Barton wikipedia"
                            to="https://en.wikipedia.org/wiki/Great_Barton"
                            target="_blank" rel="noopener noreferrer">
                            Great Barton wikipedia
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Village Hall"
                            to="https://www.greatbartonvh.onesuffolk.net"
                            target="_blank" rel="noopener noreferrer">
                            Village Hall
                        </Link>
                    </li>
                </ul>
                <h3 >Allotment Related Websites</h3>
                <ul>
                    <li>
                        <Link
                            title="Gardening Shows and Events"
                            to="https://www.allotmentonline.co.uk/gardening-shows-and-events/"
                            target="_blank" rel="noopener noreferrer">
                            Gardening Shows and Events
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Suffolk Wildlife Trust"
                            to="https://www.suffolkwildlifetrust.org/"
                            target="_blank" rel="noopener noreferrer">
                            Suffolk Wildlife Trust
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Royal Horticultural Society"
                            to="https://www.rhs.org.uk/"
                            target="_blank" rel="noopener noreferrer">
                            Royal Horticultural Society
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="National Allotment Association"
                            to="https://www.nsalg.org.uk/"
                            target="_blank" rel="noopener noreferrer">
                            National Allotment Association
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Suffolk Allotments"
                            to="https://www.suffolkallotments.org.uk/"
                            target="_blank" rel="noopener noreferrer">
                            Suffolk Allotments
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="National Allotment Society"
                            to="http://www.nsalg.org.uk"
                            target="_blank" rel="noopener noreferrer">
                            National Allotment Society
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Social Farms & Gardens"
                            to="https://www.farmgarden.org.uk/ari"
                            target="_blank" rel="noopener noreferrer">
                            Social Farms & Gardens
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Allotment Grow Your Own"
                            to="http://www.allotment.org.uk"
                            target="_blank" rel="noopener noreferrer">
                            Allotment Grow Your Own
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Allotment Regeneration Initiative"
                            to="http://www.farmgarden.org.uk/ari"
                            target="_blank" rel="noopener noreferrer">
                            Allotment Regeneration Initiative
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Allotments 4 All"
                            to="http://www.allotments4all.co.uk"
                            target="_blank" rel="noopener noreferrer">
                            Allotments 4 All
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Gardeningknowhow.com"
                            to="https://www.gardeningknowhow.com/edible"
                            target="_blank" rel="noopener noreferrer">
                            Gardeningknowhow.com
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="TinShed Flower Farm"
                            to="https://www.flowersfromthefarm.co.uk/events/"
                            target="_blank" rel="noopener noreferrer">
                            TinShed Flower Farm
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="r/Vegtable Growing on Reddit"
                            to="https://www.reddit.com/r/vegetablegardening/"
                            target="_blank" rel="noopener noreferrer">
                            r/Vegtable Growing on Reddit
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Gardeners World Allotment Calendar"
                            to="https://www.gardenersworld.com/how-to/grow-plants/allotment-year-planner/"
                            target="_blank" rel="noopener noreferrer">
                            Gardeners World Allotment Calendar
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Allotment Etiquette"
                            to="https://www.gardenersworld.com/how-to/grow-plants/allotment-etiquette/"
                            target="_blank" rel="noopener noreferrer">
                            Allotment Etiquette
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Gardeners World: How to start an allotment"
                            to="https://www.gardenersworld.com/how-to/grow-plants/how-to-start-an-allotment/"
                            target="_blank">
                            Gardeners World: How to start an allotment
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="NO Dig Method"
                            to=" https://charlesdowding.co.uk/start-no-dig/"
                            target="_blank" rel="noopener noreferrer">
                            NO Dig Method
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Allotments you can visit"
                            to="https://www.heritageopendays.org.uk/news-desk/news/5-fabulous-allotments"
                            target="_blank" rel="noopener noreferrer">
                            Allotments you can visit
                        </Link>
                    </li>
                </ul>
            </nav >
        </div>
    )
}

export default LinksPage