import React, { useState, useEffect, Suspense, lazy } from "react";
import { HashRouter as Router, Routes, Route, } from "react-router-dom";

import CustomThrobber from './components/CustomThrobber';

import NavBar from "./components/NavBar";
import Banner from "./components/Banner";
import Footer from "./components/footer";
import Constitution from "./pages/constitution";
import Committee from "./pages/committee";
import ContactPage from "./pages/contact";
import LinksPage from "./pages/links";
import NotFound from "./pages/404";
import JobsOnThePlot from "./pages/jobsOnThePlot";
import Rules from "./pages/rules";
import Home from "./pages/home"
import About from "./pages/about";

const GalleryPage = lazy(() => import('./pages/gallery'));
const GrowingGuidePage = lazy(() => import('./pages/growingGuide'));
const VideosPage = lazy(() => import(`./pages/videos`));

const App = () => {

    return (
        <>
            <Router>
                <header>
                    <NavBar />
                    <Banner />
                </header>
                <Suspense fallback={<CustomThrobber />}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />}>
                            <Route path="constitution" element={<Constitution />} />
                            <Route path="committee" element={<Committee />} />
                            <Route path="rules" element={<Rules />} />
                        </Route>
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/links" element={<LinksPage />} />
                        <Route path="/gallery" element={<GalleryPage />}>
                            <Route path=":pageNumber" element={<GalleryPage />} />
                        </Route>
                        <Route path="/videos" element={<VideosPage />} />
                        <Route path="/growing-guide" element={<GrowingGuidePage />} />
                        <Route path="/jobsontheplot" element={<JobsOnThePlot />}>
                            <Route path=":month" element={<JobsOnThePlot />} />
                        </Route>
                        <Route path="/404" element={<NotFound />} />
                    </Routes>
                    <Footer />
                </Suspense>
            </Router>
        </>
    );
}

export default App;
