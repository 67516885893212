import React from "react"

const Rules = () => {
    
    return (<>
        <div className="rules">
            <h3>General Rules you may wish to know about:</h3>
            <ul>
                <li>
                    SECURITY: All plot holders will be issued with security access codes for the gate and the Container.
                    Codes shall not be shared or passed onto anyone that does not have a plot. The main gate should remain
                    closed at all times when not entering or leaving the site. It is critical that the container be locked when
                    leaving the plot. If you have not opened it and you are the last person to leave, please secure it as there is
                    equipment of value stored in the container.
                </li>
                <li>
                    Animals: No animals can be kept on the plot but well controlled dogs can visit if kept on leads and kept off
                    other plots
                </li>
                <li>
                    Inspection: Inspections of plots are a standard practice for all allotments. These happen as and when the
                    committee deem it necessary. This is to ensure the site is safe, secure and the plots are fully utilised.
                </li>
                <li>
                    Sheds and Ponds: no buildings or ponds can be erected on GBA Plots
                </li>
                <li>
                    Water: Water is generally available using the IBC’s that collect the rain from the polytunnel. The committee
                    arranges for the water to be pumped into the surrounding IBC’s when it is possible. There is no running
                    water available but creative solutions can be found but consider the aesthetics of such creative solutions.
                    Keep in mind that water butts holding stagnant water in warm weather (over 20*C) can develop legionella.
                    This can be controlled by additives, such as chlorine, to the water butts itself.
                </li>
                <li>
                    Weeds and self-seeding plants: It is the responsibility of the plot holder to manage weeds and self-seeding
                    plants, including flowers. Please be considerate of your fellow plot holders as unchecked weeds and self-
                    seeding plants can infiltrate surrounding areas in a matter of weeks.
                </li>
                <li>
                    Car Parking: please do not park directly outside the gate as farm equipment use this regularly. There is
                    limited parking available on the verge opposite the allotments.
                </li>
                <li>
                    Chemicals: only commercially available chemicals, available at horticultural suppliers, shall be used for
                    the control of pests, diseases or vegetation.
                    Your contract will go into more detail in regards to the general rules.
                </li>
            </ul>
        </div>
    </>)
}

export default Rules