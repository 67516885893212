import React from "react";

const NotFound=()=>{
    return (<>
        <div className="notFoundPage">
            <h3>Not Found</h3>
            <h4>Sorry , Page Not Found</h4>
            <p>The page you requested could not be found.</p>
        </div>
    </>)
}

export default NotFound