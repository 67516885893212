import React, { useEffect, useState } from "react";

import {
    BsFillSunFill,
    BsCloud,
    BsFillCloudDrizzleFill,
    BsFillCloudRainHeavyFill,
    BsFillCloudLightningFill,
    BsFillCloudLightningRainFill,
    BsFillCloudFogFill,
    BsFillCloudSnowFill,
    BsFillCloudHailFill,
    BsFillSunriseFill,
    BsFillSunsetFill,
} from "react-icons/bs"
import {
    BiArrowToTop,
    BiArrowToBottom
}   from "react-icons/bi"
import { useNavigate } from "react-router-dom";

const weatherCodes = [
    { code: [0], desc: "Clear sky", icon: <BsFillSunFill /> },
    { code: [1, 2, 3], desc: "Mainly clear, partly cloudy, overcast", icon: <BsCloud /> },
    { code: [45, 48], desc: "Fog", icon: <BsFillCloudFogFill /> },
    { code: [51, 53, 55], desc: "Drizzle", icon: <BsFillCloudDrizzleFill /> },
    { code: [56, 57], desc: "Freezing Drizzle", icon: <BsFillCloudRainHeavyFill /> },
    { code: [61, 63, 65], desc: "Rain", icon: <BsFillCloudRainHeavyFill /> },
    { code: [66, 67], desc: "Freezing Rain", icon: <BsFillCloudRainHeavyFill /> },
    { code: [71, 73, 75], desc: "Snow fall", icon: <BsFillCloudSnowFill /> },
    { code: [77], desc: "hail", icon: <BsFillCloudHailFill /> },
    { code: [80, 81, 82], desc: "Rain showers", icon: <BsFillCloudDrizzleFill /> },
    { code: [85, 86], desc: "Snow showers", icon: <BsFillCloudSnowFill /> },
    { code: [95], desc: "Thunderstorm", icon: <BsFillCloudLightningFill /> },
    { code: [96, 99], desc: "Thunderstorm with hail", icon: <BsFillCloudLightningRainFill /> },
]

const CurrentWeather = () => {
    const navigate = useNavigate()
    const d = new Date();
    const currentDay = d.getDate()
    const currentMonth = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
    const currentYear = d.getFullYear()
    const currentHour = d.getHours();
    const now = `${currentYear}-${currentMonth}-${currentDay < 10 ? `0${currentDay}` : currentDay}T${currentHour < 10 ? `0${currentHour}` : currentHour}:00`
    const weather = getWeather()
    const [currentWeather, setCurrentWeather] = useState({})

    async function getWeather() {
        const weather = await fetch(
            "https://api.open-meteo.com/v1/forecast?latitude=52.2726&longitude=0.7668&hourly=temperature_2m,weathercode,is_day&daily=weathercode,temperature_2m_max,temperature_2m_min,sunrise,sunset&timezone=Europe%2FLondon&past_days=2&forecast_days=3"
        )
            .then((res) => res.json())
            // to get error
            .catch((err) => console.log(err))
        return weather
    }
    useEffect(() => {
        weather.then((data) => {
            let index = data.hourly.time.findIndex((time) => {
                if (time == now) {
                    return true
                }
            })
            const sunrise = data.daily.sunrise.find((time) => {
                const nowSlice = `${currentYear}-${currentMonth}-${currentDay < 10 ? `0${currentDay}` : currentDay}`
                const timeSlice = time.slice(0, time.length - 6)
                return timeSlice == nowSlice
            }).slice(11)
            const sunset = data.daily.sunset.find((time) => {
                const nowSlice = `${currentYear}-${currentMonth}-${currentDay < 10 ? `0${currentDay}` : currentDay}`
                const timeSlice = time.slice(0, time.length - 6)
                return timeSlice == nowSlice
            }).slice(11)
            data.hourly.weathercode.find((code, i) => {
                if (i == index) {
                    weatherCodes.find((weatherCode) => {
                        if (weatherCode.code.includes(code)) {
                            setCurrentWeather(
                                { 
                                    tempMax: `${data.daily.temperature_2m_max[0]}${data.daily_units.temperature_2m_max}`,
                                    tempMin: `${data.daily.temperature_2m_min[0]}${data.daily_units.temperature_2m_min}`,
                                    sunrise: sunrise,
                                    sunset: sunset,
                                    desc: weatherCode.desc, 
                                    icon: weatherCode.icon,
                                }
                            )
                        }
                    })
                }
            })
        })
    }, [])

    return (<>
        <div className="weatherWidget"  title={currentWeather.desc} onClick={()=>window.open("https://www.bbc.com/weather/2648164")}>
            <span>
                <span>
                    {currentWeather.icon}
                </span>
                <button title={`Min Temp.: ${currentWeather.tempMin}`}>
                    <BiArrowToBottom/>
                    <p>{currentWeather.tempMin}</p>
                </button>
                <button title={`Max Temp: ${currentWeather.tempMax}`}>
                    <BiArrowToTop/>
                    <p>{currentWeather.tempMax}</p>
                </button>
                <button title={`Sunrise: ${currentWeather.sunrise}`}>
                    <BsFillSunriseFill/>
                    <p>{currentWeather.sunrise}</p>
                </button>
                <button title={`Sunset: ${currentWeather.sunset}`}>
                    <BsFillSunsetFill/>
                    <p>{currentWeather.sunset}</p>
                </button>
            </span>
        </div>
    </>)
}

export default CurrentWeather