import React, { useEffect, useState } from "react";

const Banner=()=>{
    return (
        <div className="homeBanner">
            <h1>Great Barton Allotments</h1>
            <h2>Growing Together</h2>
        </div>
    )
}

export default Banner