import React from "react";
import { Link } from "react-router-dom";


const Home = () => {

    return (<>
        <div className="homePage">
            <h3>Welcome!</h3>
            <p>We are a diverse community of locals that share a common interest. Every season has its own successes
                and challenges, sometimes your plants don’t make it, for many reasons. Sometimes this is out of your control, and
                often a bit of mystery but the great thing about being part of a community like this is there is plenty of advice on hand.
                If you don’t want advise, you will learn by watching what other people make a success and failure of but there is
                always our <Link to="growing-guide">Growing Guide.</Link></p>
            <ul>
                <p>Key facts about the suffolk society of allotment and leisure gardeners: </p>
                <li>
                    Great Barton Allotment Association consists of 33 plots of various sizes.
                    it is the voice of the national society of allotment and leisure gardeners in suffolk
                </li>
                <li>
                    New elections for committee members are at the Annual General Meeting each Autumn and everyone is welcome to put
                    their name forward!
                    consists of representatives of allotment groups in suffolk that are affiliated to the national group
                </li>
                <li>
                    provides a local forum for allotmenteers
                </li>
                <li>
                    offers help and advice to allotmenteers on forming organised allotment groups
                </li>
                <li>
                    promotes allotmenteering as a healthy and rewarding pastime
                </li>
                <li>
                    has direct links with about 2500 allotmenteers
                </li>
                <li>
                    has direct links with  35 organised allotment groups in suffolk
                </li>
            </ul>

            <h4>FAQ</h4>
            <h5><i>I'd like an allotment but how long will I have to wait?”</i></h5>
            <p>It very much depends on demand so it's difficult to give a definite answer to this.   We have never known anyone to wait more than 3 years but we had an unprecedented increase in plot requests during Covid which led to the waiting list expanding.  It must be noted however that Great Barton Residents have precedence over anyone from outside the Parish.  That said, if you are from outside the Parish and you are on the waiting list for 6 months or more you will be allocated or I should say offered a plot based on the date you enquired compared to Great Barton Residents</p>
            <h5><i>“How do I apply?”</i></h5>
            <p>Send an email to: <Link to="mailto:gballotments@gmail.com">gballotments@gmail.com</Link> You will get a response from the Great Barton Allotments Secretary who manages the emails on behalf of the wider Committee</p>
            <h5><i>“How much does an allotment cost?</i></h5>
            <p>Plots come in two sizes either full or half size and price will depend on the size you wish to have, this cost includes public liability insurance for the Plot Holder who is named on the contract.  For any other Persons that will work your plot and additional £3.00 to cover them and must also be named on the contract. For specific prices send us an email.</p>
            <h5><i>“Can I put a shed, greenhouse or fence on my plot?</i></h5>
            <p>Unfortunately Buildings are not allowed to be erected on any plots.   Further to this, full size trees, ponds or animals such as Bee Hives & Chickens are not allowed.   This is to comply with the lease we hold with Great Barton Parish Council.</p>
            <h5><i>"What happens if I cannot continue to cultivate my allotment?"</i></h5>
            <p>We strive to be good communicators as a Committee and as Plot Holders. Please communicate with the Committee when and or if you experience this challenge.   We recognise sometimes life gets in the way of having an allotment and you may need a short break, assistance or you have decided it's just not doable anymore.  Whatever your reasons for not being able to continue, the Great Barton allotments committee members can facilitate.
                An important note to make is; if you give up on your plot, we kindly ask that it be cleared for the next plotholder. </p>
            <p>For more info: <Link to={"about/constitution"}>Read Our Constitution</Link></p>
        </div>
    </>)
}

export default Home