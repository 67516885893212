import React from "react";
import { Link } from "react-router-dom";

const ContactPage = () => {

    return (<div className="contactPage">
        <h3>Contact</h3>
        <p>If you would like to apply for a plot or have any queries at all send us an email</p>
        <Link to="mailto:gballotments@gmail.com">gballotments@gmail.com</Link>
        <p>Green Lane, Great Barton, Bury Saint Edmunds IP31 2PJ, United Kingdom</p>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8859.229033946885!2d0.7840369728873854!3d52.26631476565657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d9b350df8943f7%3A0x71904584701e88ac!2sGreat%20Barton%20Allotments!5e1!3m2!1sen!2sus!4v1692700074845!5m2!1sen!2sus" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>)
}

export default ContactPage
