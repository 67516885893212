import React from "react";

const Committee = () => {
    return (<>
        <div className="committeePage">
            <h3>Commitee</h3>
            <span>
                <div>
                    <img src={require("../../public/assets/images/committee/johnLight.jpg")} />
                    John Light<br />
                    Chairman
                </div>
                <div>
                    <img src={require("../../public/assets/images/committee/jamesThomas.jpg")} />
                    James Thomas<br />
                    Assistant Chair
                </div>
            </span>
            <span>
                <div>
                    <img src={require("../../public/assets/images/committee/davidJiggins.jpg")} />
                    David Jiggins<br />
                    Treasurer
                </div>
                <div>
                    <img
                        style={{
                            WebkitMask: `url(${require("../../public/assets/images/committee/noProfileImage.svg")}) center / contain no-repeat`,
                            mask: `url(${require("../../public/assets/images/committee/noProfileImage.svg")}) center / contain no-repeat`,
                            zIndex:"-1"
                        }}
                    />
                    Sheri Chick<br />
                    Secretary
                </div>
            </span>
            <span>
                <div>
                    <img src={require("../../public/assets/images/committee/michealUnderwood.jpg")} />
                    Micheal Underwood
                </div>
                <div>
                    <img src={require("../../public/assets/images/committee/clareRochford.jpg")} />
                    Clare Rochford
                </div>
                <div>
                    <img
                        style={{
                            WebkitMask: `url(${require("../../public/assets/images/committee/noProfileImage.svg")}) center / contain no-repeat`,
                            mask: `url(${require("../../public/assets/images/committee/noProfileImage.svg")}) center / contain no-repeat`,
                            zIndex:"-1"
                        }}
                    />
                    Peter Fisk
                </div>
                <div>
                    <img
                        style={{
                            WebkitMask: `url(${require("../../public/assets/images/committee/noProfileImage.svg")}) center / contain no-repeat`,
                            mask: `url(${require("../../public/assets/images/committee/noProfileImage.svg")}) center / contain no-repeat`,
                            zIndex:"-1"
                        }}
                    />
                    Robert Denny
                </div>
                <div>
                    <img
                        style={{
                            WebkitMask: `url(${require("../../public/assets/images/committee/noProfileImage.svg")}) center / contain no-repeat`,
                            mask: `url(${require("../../public/assets/images/committee/noProfileImage.svg")}) center / contain no-repeat`,
                            zIndex:"-1"
                        }}
                    />
                    Kieth Frost
                </div>
            </span>
        </div>
    </>)
}

export default Committee