import React, {useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
    AiOutlinePicture,

} from "react-icons/ai"
import { BiHomeAlt2 } from "react-icons/bi"
import { BsQuestionLg } from "react-icons/bs"
import { GiHamburgerMenu } from "react-icons/gi"
import { HiAtSymbol } from "react-icons/hi"
import { RxVideo } from "react-icons/rx"
import { PiLinkSimpleBold } from "react-icons/pi"
import {PiPlantFill} from "react-icons/pi"
import {LuShovel} from "react-icons/lu"

import Dropdown from "./Dropdown";

const NavBar = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation();

    useEffect(() => {
        const scrollToTop = () => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        };

        scrollToTop();
      }, [pathname]);

    return (
        <nav className="navBar">
            <Dropdown
                menuPlacement={window.innerWidth < "750" ? { right: 0 } : {}}
                label={
                    <GiHamburgerMenu />
                }>
                <button
                    className={`${pathname == "/" && "buttonCurrentPage"}`}
                    onClick={() => {
                        
                        return navigate("/")}}>
                    <p>Home</p>
                </button>
                <button
                    className={`${pathname.slice(0, 6) == "/about" && "buttonCurrentPage"}`}
                    onClick={() => {
                        
                        return navigate("/about")}}>
                    <p>About</p>
                </button>
                <button
                    className={`${pathname == "/gallery" && "buttonCurrentPage"}`}
                    onClick={() => {
                        
                        return navigate("/gallery")}}>
                    <p>Gallery</p>
                </button>
                <button
                    className={`${pathname == "/videos" && "buttonCurrentPage"}`}
                    onClick={() => {
                        
                        return navigate("/videos")}}>
                    <p>Videos</p>
                </button>
                <button
                    className={`${pathname == "/contact" && "buttonCurrentPage"}`}
                    onClick={() => {
                        
                        return navigate("/contact")}}>
                    <p>Contact</p>
                </button>
                <button
                    className={`${pathname == "/links" && "buttonCurrentPage"}`}
                    onClick={() => {
                        
                        return navigate("/links")}}>
                    <p>Links</p>
                </button>
            </Dropdown>
            <button
                title="Home"
                className={`${pathname == "/" && "buttonCurrentPage"}`}
                onClick={() => {
                    
                    return navigate("/")}}>
                <BiHomeAlt2 />
                <p>Home</p>
            </button>
            <button
                title="Growing Guide"
                className={`${pathname == "/growing-guide" && "buttonCurrentPage"}`}
                onClick={() => {
                    
                    return navigate("/growing-guide")}}>
                <PiPlantFill />
                <p>Grow Guide</p>
            </button>
            <button
                title="Jobs on the Plot"
                className={`${pathname.includes("/jobsontheplot") && "buttonCurrentPage"}`}
                onClick={() => {
                    
                    return navigate("/jobsontheplot")}}>
                <LuShovel />
                <p>Jobs on the plot</p>
            </button>
            <button
                title="About"
                className={`${pathname.includes("/about") && "buttonCurrentPage"}`}
                onClick={() => {
                    
                    return navigate("/about")}}>
                <BsQuestionLg />
                <p>About</p>
            </button>
            <button
                title="Gallery"
                className={`${pathname.includes("/gallery") && "buttonCurrentPage"}`}
                onClick={() => {
                    
                    return navigate("/gallery")}}>
                <AiOutlinePicture />
                <p>Gallery</p>
            </button>
            <button
                title="Videos"
                className={`${pathname.includes("/videos") && "buttonCurrentPage"}`}
                onClick={() => {
                    
                    return navigate("/videos")}}>
                <RxVideo />
                <p>Videos</p>
            </button>
            <button
                title="Links"
                className={`${pathname.includes("/links") && "buttonCurrentPage"}`}
                onClick={() => {
                    
                    return navigate("/links")}}>
                <PiLinkSimpleBold />
                <p>Links</p>
            </button>
            <button
                title="Contact"
                className={`${(pathname.includes("/apply") || pathname.includes("/contact")) && "buttonCurrentPage"}`}
                onClick={() => {
                    
                    return navigate("/contact")}}>
                <HiAtSymbol />
                <p>Contact</p>
            </button>
        </nav>
    )
}

export default NavBar