import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const About = () => {
    const navigate = useNavigate()
    const page = useLocation().pathname.slice(7)

    const style=(buttonName)=>{
        if(buttonName==page){
            return  "activeButton"
        }else if(page==""){
            return "activeButton"
        }else{
            return ""
        }
    }

    return (<>
        <div className="aboutPage">
            <h3>About Us</h3>
            <nav>
                 <button
                    className={style("committee")}
                    onClick={() => navigate("/about/committee")}>
                    Committee
                 </button>
                 <button 
                    className={style("constitution")}
                    onClick={() => navigate("/about/constitution")}>
                    Constitution
                 </button>
                 <button 
                    className={style("rules")}
                    onClick={() => navigate("/about/rules")}>
                    Rules
                 </button>
            </nav>
            <img src={require("../../public/assets/images/imageGallery/15560.jpg")} />
            <p>The Great Barton Allotments Association, after preparatory discussions, held an Inaugural Meeting in early December 2012 in the Great Barton Village Hall. A constitution was agreed and a committee was elected. A local farmer, James Boreham, ploughed the site and work soon started to prepare the plot in Green Lane, measuring the perimeter and marking out of pathways and individual plots. A fence then enclosed the site, complete with 5 bar gate, and paths were literally “stamped out”!</p>
            <Outlet />
        </div>
    </>)
}

export default About