import React from "react";
import { Link } from "react-router-dom"
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai"
import { IoLogoYoutube } from "react-icons/io"
import { MdLocationOn } from "react-icons/md"
import { SlGraph } from "react-icons/sl"
import CurrentWeather from "./CurrentWeather"

const Footer = () => {
    const d = new Date()
    return (<>
        <footer>
            <p><b>©</b> G.B. Allotments {d.getFullYear()}</p>
            <Link to="contact">Feedback</Link>
            <CurrentWeather />
            <span>
                <Link
                    title="Facebook - GB Allotments"
                    to={"https://www.facebook.com/profile.php?id=61550843213230"}
                    target="_blank" rel="noopener noreferrer">
                    <AiFillFacebook />
                </Link>
                <Link
                    title="Youtube - GB Allotments"
                    to={"https://www.youtube.com/@gballotments3821"}
                    target="_blank" rel="noopener noreferrer">
                    <IoLogoYoutube />
                </Link> 
                <Link
                    title="Instagram - GB Allotments"
                    to={"https://www.instagram.com/gballotments/"}
                    target="_blank" rel="noopener noreferrer">
                    <AiFillInstagram />
                </Link>
                <Link
                    title="Google Maps - GB Allotments"
                    target="_blank" 
                    to="https://www.google.com/maps/place/Great+Barton+Allotments/@52.267082,0.786543,9637m/data=!3m1!1e3!4m6!3m5!1s0x47d9b350df8943f7:0x71904584701e88ac!8m2!3d52.2670822!4d0.7865433!16s%2Fg%2F11cs9msmbk?hl=en&entry=ttu">
                    <MdLocationOn />
                </Link>
                <Link
                    title="Suffolk County Council - suffolk.gov.uk"
                    to="https://www.suffolk.gov.uk/"
                    target="_blank">
                    <img src={require("../../public/assets/suffolkCountyCouncilLogo.svg")} />
                </Link>
                <Link
                    title="West Suffolk Council - westsuffolk.gov.uk"
                    to="https://www.westsuffolk.gov.uk/"
                    target="_blank">
                    <img src={require("../../public/assets/westSuffolkLogo.svg")} />
                </Link>
            </span>
        </footer>
    </>)
}

export default Footer